<template>
  <div class="container">
    <ul>
      <li>{{ this.userAgent() }}</li>
      <li>{{ this.browser() }}</li>
      <li>{{ location }}</li>
      <li>{{ getProfile.name }}: {{ getProfile.email }}</li>
    </ul>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import ToeflMixin from "@/mixins/toefl.js";

export default {
  metaInfo() {
    return {
      title: "SystemCheck - " + this.CompanyName
    };
  },

  components: {},

  mixins: [ToeflMixin],

  props: [],
  data() {
    return {
      location: ""
    };
  },
  computed: {
    ...mapState("user", ["token", "lang"]),
    ...mapGetters("user", ["token", "getProfile"])
  },
  async mounted() {
    const { iso_code } = await this.$store.dispatch("ipGeo/getGeoLocation");
    this.location = iso_code;
  },

  methods: {}
};
</script>

<style scoped></style>
